<template>
  <y-form ref="form" class="w-full">
    <div class="flex flex-col gap-4 w-full">
      <div class="flex items-center gap-4 w-full 2xs:flex-row flex-col">
        <y-input
          label="Ad Soyad"
          :rules="['REQUIRED']"
          v-model="formData.name"
          class="w-full 2xs:w-1/2-gap-4"
          placeholder="İsminiz ve soysiminiz"
        ></y-input>
        <y-input
          label="Mail Adresi"
          type="text"
          v-model="formData.mail"
          :rules="['REQUIRED', 'EMAIL']"
          class="w-full 2xs:w-1/2-gap-4"
          placeholder="E-Posta adresiniz"
        ></y-input>
      </div>
      <div class="flex items-center gap-4 w-full 2xs:flex-row flex-col">
        <y-input
          label="Cep Telefonu"
          maskType="phone"
          v-model="formData.phone"
          :rules="['REQUIRED']"
          class="w-full 2xs:w-1/2-gap-4"
          placeholder="Telefon Numranınız"
        ></y-input>
        <y-input
          label="Firma Ünvanı"
          v-model="formData.company"
          :rules="['REQUIRED']"
          class="w-full 2xs:w-1/2-gap-4"
          placeholder="Firma Ünvanınız"
        ></y-input>
      </div>
      <div class="flex items-center gap-4 w-full 2xs:flex-row flex-col">
        <y-combobox
          :items="cities"
          class="w-full 2xs:w-1/2-gap-6"
          label="Katılım Sağladığınız İl"
          v-model="formData.city"
          :rules="['REQUIRED']"
          placeholder="Şehir"
          @update:modelValue="changeCity"
        >
          <template #selection="{ item }">
            {{ item }}
          </template>
          <template #select-item="{ item }">
            {{ item }}
          </template>
        </y-combobox>
        <y-input
          label="Kaç Gece Konaklama Yaptınız"
          v-model="formData.layoverCount"
          :rules="['REQUIRED']"
          class="w-full 2xs:w-1/2-gap-4"
          type="number"
          placeholder="Gün Sayısı"
        ></y-input>
      </div>
      <div class="flex flex-col gap-3 items-start">
        <div class="flex flex-col gap-1">
          <h1 class="text-lg text-BLACK-2 font-semibold">Ulaşım Bilgileri</h1>
          <p class="text-GREY-1 text-xs font-medium">
            Buradan hangi ulaşım aracıyla kaç kilometre yaptıysanız
            ekleyebilirsiniz
          </p>
        </div>
        <div
          v-for="(item, index) in formData.arrival"
          :key="item"
          class="flex items-center gap-4 w-full 2xs:flex-row flex-col"
        >
          <div class="flex flex-col gap-2 w-full 2xs:w-1/2-gap-6">
            <p class="text-sm text-BLACK-2 font-semibold">Ulaşım Aracı</p>
            <y-dropdown class="w-full">
              <y-dropdown-item
                v-for="arrivalType in arrivalTypes"
                :key="arrivalType"
                @click="formData.arrival[index].type = arrivalType"
              >
                {{ arrivalType }}
              </y-dropdown-item>
              <template #activator="{ open }">
                <button
                  @click="open"
                  class="relative w-full h-[44px] flex items-center border border-BORDER rounded-lg text-sm px-4"
                >
                  <span v-if="formData.arrival[index].type">{{
                    formData.arrival[index].type
                  }}</span>
                  <span v-else class="text-GREY-1">Araç Tipi Seçiniz</span>
                  <y-icon name="yi yi-arrow-down text-GREY-1 ml-auto"></y-icon>
                </button>
              </template>
            </y-dropdown>
          </div>
          <y-input
            label="Araçla Yapılan Kilometre"
            v-model="formData.arrival[index].kilometer"
            :rules="['REQUIRED']"
            class="w-full 2xs:w-1/2-gap-4"
            :suffix="index > 0"
            placeholder="Km değeri"
          >
            <template #suffix>
              <y-button
                @click="deleteArrivalType(index)"
                circle
                icon="yi yi-delete text-RED"
              ></y-button>
            </template>
          </y-input>
        </div>
        <y-button
          @click="addNewArrivalType"
          text
          icon="yi yi-add mr-1"
          class="text-GREEN"
        >
          Yeni varış tipi ekle
        </y-button>
      </div>
    </div>
    <div class="flex items-center justify-end">
      <y-button
        @click="saveForm"
        :loading="isLoading"
        :disabled="isLoading"
        primary
        class="max-w-max bg-GREEN hover:bg-TEAL"
      >
        Kaydet
      </y-button>
    </div>
  </y-form>
</template>
<script setup>
import { ref, defineEmits } from "vue";

const cities = ref([
  "Konya",
  "İstanbul",
  "Ankara",
  "Bursa",
  "Aydın",
  "Diyarbakır",
  "Bitlis",
  "Denizli",
  "Kocaeli",
  "Tunceli",
  "Kırklareli",
  "Kırşehir",
  "Trabzon",
  "Zonguldak",
  "Bartın",
  "Sivas",
  "Çanakkale",
  "Eskişehir",
  "Artvin",
  "Iğdır",
  "Mersin",
  "Tekirdağ",
  "Ordu",
  "Aksaray",
  "Bolu",
  "Burdur",
  "Hatay",
  "Isparta",
  "Niğde",
  "Kırıkkale",
  "Şırnak",
  "Ardahan",
  "Karaman",
  "Kilis",
  "Erzurum",
  "Karabük",
  "Mardin",
  "Kastamonu",
  "Osmaniye",
  "Ağrı",
  "Samsun",
  "Sakarya",
  "Şanlıurfa",
  "Rize",
  "Gaziantep",
  "Kahramanmaraş",
  "Amasya",
  "Hakkari",
  "Yozgat",
  "Manisa",
  "Adana",
  "Batman",
  "Adıyaman",
  "Antalya",
  "Çankırı",
  "Gümüşhane",
  "Kütahya",
  "Nevşehir",
  "Çorum",
  "İzmir",
  "Kars",
  "Muş",
  "Siirt",
  "Tokat",
  "Elazığ",
  "Balıkesir",
  "Düzce",
  "Yalova",
  "Uşak",
  "Sinop",
  "Afyonkarahisar",
  "Erzincan",
  "Muğla",
  "Bayburt",
  "Bilecik",
  "Bingöl",
  "Edirne",
  "Giresun",
  "Malatya",
  "Van",
  "Kayseri",
]);

const arrivalTypes = ref(["Araç", "Uçak", "Hızlı Tren", "Otobüs", "Tramvay"]);
const form = ref(null);
const isLoading = ref(false);

const emit = defineEmits(["saveForm"]);

const formData = ref({
  name: "",
  mail: "",
  phone: "",
  company: "",
  city: "",
  layoverCount: null,
  arrival: [
    {
      type: arrivalTypes.value[0],
      kilometer: null,
    },
  ],
});

const citysDistance = {
  Konya: 15,
  İstanbul: 700,
  Ankara: 265,
  Bursa: 481,
  Aydın: 506,
};

const types = {
  Araç: 0.172458436241611,
  Uçak: 0.182869354362416,
  "Hızlı Tren": 0.0354629637583893,
  Otobüs: 0.102150394630872,
  Tramvay: 0.028603267114094,
};

const changeCity = (city) => {
  if (citysDistance[city]) {
    formData.value.arrival[0].kilometer = citysDistance[city];
  } else {
    formData.value.arrival[0].kilometer = 0;
  }
};

const calculate = () => {
  let result = 0;
  for (const arrival of formData.value.arrival) {
    console.log('ARRIVAL', arrival);
    const vehicleConst = types[arrival.type];
    console.log('vehicleConst', vehicleConst);
    const abc = vehicleConst * arrival.kilometer;
    console.log('ABC', abc);
    if (arrival.type !== "Uçak") {
      result += (abc / 1000);
    } else {
      result += abc;
    }
  }

  console.log('RES 1', result);

  if (formData.value.layoverCount) {
    result += (32.1 * formData.value.layoverCount) / 1000;
  }
  console.log('RES 2', result);
  return result;
};

const saveForm = () => {
  if (form.value.validate()) {
    isLoading.value = true;
    console.log("valid");
    const carbon = calculate();
    formData.value.carbon = Number(carbon.toFixed(4)).toString();

    let db = JSON.parse(localStorage.getItem("data") || "[]");
    const data = formData.value;

    db.push(data);
    localStorage.setItem("data", JSON.stringify(db));
    formData.value = data;

    fetch("https://worker-karbon-form.mucahit9832.workers.dev", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(() => {
        emit("saveForm", formData.value);
        isLoading.value = false;
      })
      .catch((err) => {
        console.error(err);
        isLoading.value = false;
      });
  }
};

const addNewArrivalType = () => {
  formData.value.arrival.push({
    type: "",
    kilometer: null,
  });
};

const deleteArrivalType = (index) => {
  formData.value.arrival.splice(index, 1);
};
</script>
