<template>
  <div
    class="w-screen h-[100vh] !h-[100svh] bg-LIGHTBLUE-6 flex items-center justify-center p-10"
  >
    <div
      class="w-full xs:w-3/4 lg:w-1/2 bg-white h-full max-h-fit border border-BORDER rounded-lg shadow-lg p-12 pr-8"
    >
      <div class="flex flex-col gap-5 pr-4 overflow-auto h-full">
        <div class="flex items-center gap-6 flex-col xs:flex-row">
          <div class="w-1/2-gap-6 xs:h-32">
            <img
              src="@/assets/images/yaka-karti-logolar-1.png"
              class="w-full h-full object-contain"
              alt=""
            />
          </div>
          <div class="w-1/2-gap-6 xs:h-32">
            <img
              src="@/assets/images/yaka-karti-logolar-2.png"
              class="w-full h-full object-contain"
              alt=""
            />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <h1 class="text-2xl text-BLACK-2 font-bold">
            İklim Değişikliği Ve Şehirlerin Geleceği Çalıştayına Hoş Geldiniz
          </h1>
          <p class="text-GREY-1 text-sm font-light">
            Bu hesaplama sayesinde etkinliğimize gelirken ne kadarlık bir karbon
            salınıma sebep olduğunuzu görebilirsiniz
          </p>
        </div>
        <RegisterForm v-if="step === 1" @save-form="saveForm"></RegisterForm>
        <RegisterSuccess
          v-else-if="step === 2"
          :name="formData.name"
          :carbon="formData.carbon"
          @clearForm="clearForm"
        ></RegisterSuccess>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import RegisterForm from "./components/RegisterForm.vue";
import RegisterSuccess from "./components/RegisterSuccess.vue";

const formData = ref("");
const step = ref(1);

const saveForm = (data) => {
  formData.value = data;
  step.value = 2;
  // const formData = new FormData();
  // for (const key in data) {
  //   formData.append(key, data[key]);
  // }
  // formData.value = data;

  // formData.append("access_key", "faaa7c65-4cc4-484b-87aa-cf9fb66f8ac8");

  // console.log("FORM DATA", formData);

  // fetch("https://api.web3forms.com/submit", {
  //   method: "POST",
  //   body: formData,
  // })
  //   .then(() => {
  //     step.value = 2;
  //   })
  //   .catch((err) => {
  //     console.console.error(err);
  //   });
};

const clearForm = () => {
  step.value = 1;
};
</script>
